import Cher from '@wearemojo/cher';

export class BackendError extends Cher {
	traceId?: string;
	traceUrl?: string;
	traceLogsUrl?: string;
	requestMethod: string;
	requestUrl: string;
	responseStatusCode?: number;
	responseBody?: string;
	responseData?: unknown;

	constructor(
		code: string,
		{
			reasons,
			meta,
			...fields
		}: {
			reasons?: Cher[];
			meta?: Record<string, unknown>;
			traceId?: string;
			traceUrl?: string;
			traceLogsUrl?: string;
			requestMethod: string;
			requestUrl: string;
			responseStatusCode?: number;
			responseBody?: string;
			responseData?: unknown;
		},
	) {
		super(code, meta, reasons);

		this.traceId = fields.traceId;
		this.traceUrl = fields.traceUrl;
		this.traceLogsUrl = fields.traceLogsUrl;
		this.requestMethod = fields.requestMethod;
		this.requestUrl = fields.requestUrl;
		this.responseStatusCode = fields.responseStatusCode;
		this.responseBody = fields.responseBody;
		this.responseData = fields.responseData;
	}
}

export type RequestOptions = {
	headers?: Record<string, string>;
	timeout?: number;
};

const defaultOptions: RequestOptions = {
	headers: {
		accept: 'application/json',
	},
};

export default function jsonClient(
	baseUrl: string,
	baseOptions: RequestOptions = {},
) {
	baseUrl = baseUrl.replace(/\/*$/, '/');
	baseOptions = mergeOptions(defaultOptions, baseOptions);

	return async <ReqT, ResT>(
		method: string,
		path: string,
		urlParams?: Record<string, string>,
		body?: ReqT,
		options?: RequestOptions,
	): Promise<ResT> => {
		path = path.replace(/^\/*/, '');
		options = mergeOptions(baseOptions, options);

		const query = urlParams
			? '?' + new URLSearchParams(urlParams).toString()
			: '';
		const url = baseUrl + path + query;

		return await makeRequest<ReqT, ResT>(method, url, body, options);
	};
}

async function makeRequest<ReqT, ResT>(
	requestMethod: string,
	requestUrl: string,
	requestBody: ReqT | undefined,
	options: RequestOptions,
): Promise<ResT> {
	let req: RequestInit = {
		method: requestMethod,
	};

	if (requestBody != null) {
		req.body = JSON.stringify(requestBody);
		req.headers = { 'content-type': 'application/json' };
	}

	req = mergeOptions(options, req);

	const reqErrorFields = {
		requestMethod,
		requestUrl,
	};

	const fetch = makeFetchWithTimeout(options.timeout);
	const response = await (async () => {
		try {
			return await fetch(requestUrl, req);
		} catch (error) {
			throw new BackendError('fetch_failed', {
				...reqErrorFields,
				meta: { error },
			});
		}
	})();

	const resErrorFields = {
		...reqErrorFields,
		traceId: response.headers.get('trace-id') ?? undefined,
		traceUrl: response.headers.get('trace-url') ?? undefined,
		traceLogsUrl: response.headers.get('trace-logs-url') ?? undefined,
		responseStatusCode: response.status,
	};

	const responseBody = await (async () => {
		try {
			return await response.text();
		} catch (error) {
			throw new BackendError('fetch_response_read_failed', {
				...resErrorFields,
				meta: { error },
			});
		}
	})();

	const errorFields = {
		...resErrorFields,
		responseBody,
		responseData: undefined as unknown,
	};

	// 2xx - success
	if (response.ok) {
		if (!responseBody) {
			return undefined as unknown as ResT;
		}

		try {
			return JSON.parse(responseBody);
		} catch (e) {
			throw new BackendError('invalid_json_response', errorFields);
		}
	}

	// any non-success codes
	// includes 4xx, 5xx and some 3xx codes
	let data: unknown;
	try {
		data = errorFields.responseData = JSON.parse(responseBody);
	} catch {}

	let cher: Cher | undefined;
	if (data && typeof data === 'object' && !Array.isArray(data)) {
		try {
			cher = Cher.parse(data as Record<string, unknown>);
		} catch {}
	}

	if (!cher) {
		throw new BackendError(`http_${response.status}`, errorFields);
	}

	throw new BackendError(cher.code, {
		reasons: cher.reasons,
		meta: cher.meta,
		...errorFields,
	});
}

function makeFetchWithTimeout(timeout?: number): typeof fetch {
	if (!timeout) return fetch;

	return async (input: RequestInfo | URL, init?: RequestInit) => {
		const controller = new AbortController();
		const signal = controller.signal;
		const timeoutId = setTimeout(() => controller.abort(), timeout);

		try {
			return await fetch(input, {
				...init,
				signal,
			});
		} finally {
			clearTimeout(timeoutId);
		}
	};
}

function mergeOptions<T extends RequestOptions | RequestInit>(
	baseOptions: T,
	newOptions?: T | RequestInit,
): T {
	newOptions = newOptions ?? ({} as T);

	return {
		...baseOptions,
		...newOptions,
		headers: {
			...baseOptions.headers,
			...newOptions.headers,
		},
	};
}
